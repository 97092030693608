import { Site } from "@libry-content/types";
import { useCommonData } from "../../components/layout/CommonDataProvider";
import { getSearchConfig } from "../searchApi";

export const useSearchConfig = () => {
  const site = useCommonData().site;
  return getSearchConfig(site);
};

export const isSearchIntegrationEnabled = (site?: Site): boolean => getSearchConfig(site)?.isSearchIntegrationEnabled;
