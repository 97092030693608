import { isNonNil, normalizeIsbn, withoutUndefinedValues } from "@libry-content/common";
import fetch from "cross-fetch";
import type { LibrarySystem } from "@libry-content/types";
import stringSimilarity from "string-similarity";
import { MarcRecord } from "./MarcRecord";
import { createSruClient, type SearchResponse, type SruClient } from "./sruClient";

export interface SimpleQuery {
  value: string;
  index: string;
}

export const NOT_SUPPORTED = "NOT_SUPPORTED" as const;

export const LIBRARY_SYSTEM_TYPES = ["bibliofil", "mikromarc", "quria", "cicero"] as const;
export type LibrarySystemType = (typeof LIBRARY_SYSTEM_TYPES)[number];
export type AdvancedSearchQuery = { isbns: string[] }; // More fields can be added as needed
export type SearchQuery = string | AdvancedSearchQuery;

type SearchFunction = (query: SearchQuery) => Promise<SearchResponse>;

export interface LibrarySystemClient {
  config: Partial<LibrarySystem>;
  baseUrl: string;

  /**
   * Test if the library system base URL can be reached and returns a valid HTTP response.
   */
  ping: () => Promise<boolean>;

  /**
   * Create URL that points to a specific catalogue record in the library system frontend.
   */
  getLinkToRecord: (recordId: string) => string;

  /**
   * Try to lookup one or more isbns and return a link to the matching record in the library
   * system frontend if found, or null if not found. If the library system does not have
   * a search API integration, the method returns a link to a pre-defined search instead.
   */
  getLinkToRecordFromIsbn: (isbn: string | string[]) => Promise<string | null>;

  /**
   * Create URL that points to a pre-defined catalogue search.
   */
  getLinkToSearch: (query: SearchQuery) => string;

  /**
   * Create URL that points to my account / min side.
   */
  getLinkToMyAccount: () => string;

  /**
   * Create URL that points to create new patron / min side.
   */
  getLinkToNewPatron: () => string | undefined;

  /**
   * Create URL that points to forgotten password / min side.
   */
  getLinkToForgottenPassword: () => string | undefined;

  /**
   * Find publications by search query using the library system API, if supported.
   */
  search: SearchFunction | typeof NOT_SUPPORTED;
}

type LibrarySystemClientFactory = (librarySystem: Partial<LibrarySystem>) => LibrarySystemClient;

const checkUrl = async (url: string) => {
  try {
    const response = await fetch(url);
    return response.ok;
  } catch {
    return false;
  }
};

const generateCommonQueryStrings = (phrase: string): string[] => {
  const trimmed = phrase.replace(/-/g, "").trim();
  if (trimmed.match(/^[0-9X]{10}$/) || trimmed.match(/^978[0-9]{10}$/)) {
    return [`dc.identifier=${trimmed}`];
  }
  const words = phrase.split(" ").filter((word) => word.length > 2);
  const phraseQueries = [`dc.title="${phrase}*"`, `cql.anywhere="${phrase}*"`];
  const wordQueries = [words.map((word) => `cql.anywhere="${word}*"`).join(" AND ")];
  return words.length > 1 ? [...phraseQueries, ...wordQueries] : phraseQueries;
};

const generateScore = (query: string, record: MarcRecord) => {
  const stringSimilarityScore = (value) => (value ? stringSimilarity.compareTwoStrings(query, value) : -0.2);

  const languageScore = (lang: string): number => {
    if (lang == "nor") return 0.2;
    if (lang == "nob") return 0.2;
    if (lang == "nno") return 0.2;
    if (lang.trim() == "") return -0.2;
    return 0;
  };

  const mediaTypeScore = (mediaType: string): number => {
    if (mediaType == "book") return 0.2;
    return 0;
  };

  return (
    stringSimilarityScore(record.getAuthor()) +
    stringSimilarityScore(record.getTitle()) +
    languageScore(record.getLanguage() ?? "") +
    mediaTypeScore(record.getMediaType() ?? "")
  );
};

const sortResults = (query: string, records: SearchResponse["records"]) => {
  return records
    .map((record) => ({
      record,
      score: generateScore(query, record),
    }))
    .sort((a, b) => b.score - a.score)
    .map((rec) => rec.record);
};

const commonSruSearch = async (sru: SruClient, query: string): Promise<SearchResponse | undefined> => {
  const queries = generateCommonQueryStrings(query);
  const responses = await Promise.all(queries.map((query) => sru.search(query)));
  return responses.find((response) => response.records.length > 0) ?? responses[0];
};

const sortedCommonSruSearch = async (sru: SruClient, query: string): Promise<SearchResponse> => {
  const response = await commonSruSearch(sru, query);

  if (!response) return { records: [], errors: [{ message: "No responses from common sru search" }] };

  return {
    records: sortResults(query, response.records),
    source: response.source,
    errors: response.errors,
  };
};

const buildUrl = (url: string, params: Record<string, string | string | undefined>) => {
  const searchParams = new URLSearchParams(withoutUndefinedValues(params));
  return `${url}?${searchParams.toString()}`;
};

const defaultSruSearch = (sru: SruClient) => (query: SearchQuery) => {
  // Simple search
  if (typeof query === "string") {
    return sortedCommonSruSearch(sru, query);
  }

  // "Advanced" search, currently very basic, but can be expanded with more fields or a proper parser as needed
  return sru.search(query.isbns.map((isbn) => `dc.identifier = "${isbn}"`).join(" OR "));
};

const getLinkToRecordFromIsbnSearch =
  (search: SearchFunction, getLinkToSearch: LibrarySystemClient["getLinkToSearch"]) =>
  async (isbn: string | string[]) => {
    const isbns = (typeof isbn === "string" ? [isbn] : isbn).map(normalizeIsbn);
    const searchResult = await search({ isbns });
    const isbnsFound = searchResult.records.map((record) => record.getIsbn()).filter(isNonNil);
    return isbnsFound.length ? getLinkToSearch({ isbns: isbnsFound }) : null;
  };

const factories: Record<LibrarySystemType, LibrarySystemClientFactory> = {
  mikromarc: (config) => {
    const host = `https://${config.database}.mikromarc.no`;
    const baseUrl = config.mikromarcUnit
      ? `${host}/mikromarc3/default.aspx?Unit=${config.mikromarcUnit}&db=${config.database}`
      : host;
    const sru = createSruClient(`${host}/mmwebapi/${config.database}/${config.mikromarcUnit}/SRU`, {
      defaultParams: { httpAccept: "text/xml" },
      defaultVersion: "2.0",
      // Mikromarc returnerer MARC 21 eller Normarc avhengig av om biblioteket er migrert eller ikke,
      // ikke avhengig av hva vi spør om.
      defaultRecordSchema: "normarc",
    });
    const parseQuery = (query: SearchQuery) => {
      if (typeof query === "string") return query;
      return query.isbns.map((isbn) => `IS = ${isbn}`).join(" OR ");
    };
    const getLinkToSearch = (query: SearchQuery) =>
      buildUrl(`${host}/mikromarc3/search.aspx`, {
        Unit: config.mikromarcUnit,
        db: config.database,
        SC: "FT",
        SW: parseQuery(query),
      });
    const search = defaultSruSearch(sru);
    return {
      config,
      baseUrl,
      getLinkToSearch,
      getLinkToRecord: (recordId: string) =>
        buildUrl(`${host}/mikromarc3/detail.aspx`, {
          Unit: config.mikromarcUnit,
          db: config.database,
          Id: recordId,
        }),
      getLinkToRecordFromIsbn: getLinkToRecordFromIsbnSearch(search, getLinkToSearch),
      getLinkToMyAccount: () =>
        buildUrl(`${host}/mikromarc3/member.aspx`, {
          Unit: config.mikromarcUnit,
          db: config.database,
        }),
      getLinkToForgottenPassword: () =>
        buildUrl(`${host}/mikromarc3/lostcode.aspx`, {
          Unit: config.mikromarcUnit,
          db: config.database,
        }),
      getLinkToNewPatron: () =>
        buildUrl(`${host}/mikromarc3/order.aspx`, {
          type: "2",
          Unit: config.mikromarcUnit,
          db: config.database,
        }),
      ping: async () => {
        try {
          const response = await fetch(baseUrl);
          // Mikromarc gir text/plain-respons ved feil Unit, så vi kan bruke Content-Type som test
          return Boolean(response.ok && response.headers.get("Content-Type")?.match(/html/));
        } catch {
          return false;
        }
      },
      search,
    };
  },

  bibliofil: (config) => {
    const host = `https://${config.database}.bib.no`;
    const instance = config.bibliofilInstance ? `m2-${config.bibliofilInstance}` : "m2";
    const m2Url = `${host}/cgi-bin/${instance}`;
    const sru = createSruClient(`${host}/cgi-bin/sru`, {
      // Bibliofil returnerer MARC 21 eller Normarc avhengig av om biblioteket er migrert eller ikke,
      // ikke avhengig av hva vi spør om. https://dok.bibsyst.no/web/webapi/webapi-uthenting.html
      defaultRecordSchema: "normarc",
      defaultVersion: "1.1",
    });
    const parseQuery = (query: SearchQuery) => {
      if (typeof query === "string") return { pubsok_txt_0: query };
      return { ccl: query.isbns.map((isbn) => `${isbn}/IS`).join(" eller ") };
    };
    const search = defaultSruSearch(sru);
    const getLinkToSearch = (query: SearchQuery) => buildUrl(m2Url, { mode: "vt", ...parseQuery(query) });
    return {
      config,
      baseUrl: m2Url,
      getLinkToSearch,
      getLinkToRecord: (recordId: string) => `${m2Url}?tnr=${recordId}`,
      getLinkToRecordFromIsbn: getLinkToRecordFromIsbnSearch(search, getLinkToSearch),
      getLinkToMyAccount: () => `${m2Url}?mode=lninfo`,
      getLinkToNewPatron: () => `${m2Url}?mode=ln-kanskjenylaaner`,
      getLinkToForgottenPassword: () => `${m2Url}?mode=sendpskjema`,
      ping: () => checkUrl(m2Url),
      search,
    };
  },

  quria: (config) => {
    const baseUrl = `https://${config.database}.arena.axiell.com`;
    const parseQuery = (query: SearchQuery) => {
      if (typeof query === "string") return query;
      return query.isbns.join(" OR ");
    };
    const getLinkToSearch = (query: SearchQuery) =>
      buildUrl(`https://${config.database}.arena.axiell.com/search`, {
        p_p_id: "searchResult_WAR_arenaportlet",
        "p_r_p_arena_urn:arena_search_type": "solr",
        "p_r_p_arena_urn:arena_search_query": parseQuery(query),
        "p_r_p_arena_urn:arena_sort_advice": "field=Relevance&direction=Descending",
      });
    return {
      config: config,
      baseUrl,
      getLinkToSearch,
      getLinkToRecord: (recordId: string) =>
        buildUrl(`${baseUrl}/results`, {
          p_p_id: "crDetailWicket_WAR_arenaportlet",
          p_p_lifecycle: "1",
          p_p_state: "normal",
          "p_r_p_arena_urn:arena_search_item_id": recordId,
        }),
      getLinkToRecordFromIsbn: async (isbn: string | string[]) => {
        const isbns = (typeof isbn === "string" ? [isbn] : isbn).map(normalizeIsbn);
        return getLinkToSearch({ isbns });
      },
      getLinkToMyAccount: () => `https://${config.database}.arena.axiell.com/protected/my-account/overview`,
      getLinkToForgottenPassword: () =>
        `https://${config.database}.arena.axiell.com/protected/my-account/profile?p_p_id=arenaAccount_WAR_arenaportlet&p_p_lifecycle=0&_arenaAccount_WAR_arenaportlet_arena_profile_mode=pin-reset`,
      getLinkToNewPatron: () => `https://${config.database}.arena.axiell.com/protected/skaffe-lånekort`,
      ping: () => checkUrl(baseUrl),
      search: "NOT_SUPPORTED",
    };
  },
  cicero: (config) => {
    const baseUrl = `https://surf.cicero-suite.com/institution/${config.database}`;
    const parseQuery = (query: SearchQuery) => {
      if (typeof query === "string") return query;
      return query.isbns.join(" OR ");
    };

    const getLinkToSearch = (query: SearchQuery) =>
      buildUrl(`${baseUrl}/search`, {
        cql: parseQuery(query),
        searchType: "NORMAL",
      });

    //TODO: Midlertidig lenke, brukes for å søke opp de gamle Micromarc ID-ene som ligger i våre Sanity-data. Når Sanity-dataene har blitt oppdatert (https://github.com/biblioteksentralen/libry-content/issues/2084) kan metoden under brukes i stedet for å få de nye ID-ene fra Cicero.
    const getLinkToRecord = (recordId: string) =>
      buildUrl(`${baseUrl}/search`, {
        cql: `onr=${recordId}`,
      });
    // const getLinkToRecord = (recordId: string) =>
    //   `${baseUrl}/record/${recordId}/%257B%2522agencyId%2522%253A%2522${config.database}%2522%252C%2522recordId%2522%253A%2522${recordId}%2522%257D`;

    return {
      config: config,
      baseUrl,
      getLinkToSearch,
      getLinkToRecord,
      getLinkToRecordFromIsbn: async (isbn: string | string[]) => {
        const isbns = (typeof isbn === "string" ? [isbn] : isbn).map(normalizeIsbn);
        return getLinkToSearch({ isbns });
      },
      getLinkToMyAccount: () => `${baseUrl}/loans`,
      getLinkToForgottenPassword: () => undefined,
      getLinkToNewPatron: () => undefined,
      ping: () => checkUrl(baseUrl), // TODO: bytt med ny funskjon checkRedirect istedetfor, dette gir bare 200 OK fordi siden redirecter umiddelbart
      search: "NOT_SUPPORTED",
    };
  },
};

export type LibrarySystemConfigInput = Omit<LibrarySystem, "_type">;
type ValidLibrarySystemConfig = Omit<LibrarySystem, "type"> & { type: LibrarySystemType };

export const isValidLibrarySystemConfig = (config?: LibrarySystemConfigInput): config is ValidLibrarySystemConfig =>
  typeof config?.type === "string" &&
  LIBRARY_SYSTEM_TYPES.includes(config?.type as LibrarySystemType) &&
  typeof config?.database === "string";

export const getLibrarySystemClient = (config?: LibrarySystemConfigInput) =>
  isValidLibrarySystemConfig(config) ? factories[config.type](config) : undefined;
