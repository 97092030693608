import fetch from "cross-fetch";

export type ForrigeBokWork = {
  id: string;
  simplifiedPresentationMetadata: SimplifiedPresentationMetadata;
  publications: ForrigeBokPublication[];
  appealTerms: AppealTerm[];
  numberOfRegistrations: number;
};

interface SimplifiedPresentationMetadata {
  title: string;
  subtitle: string;
  authors: string[];
  coverImage: string;
  summary: string;
  originalYear: string;
  genre: string[];
  about: string[];
}

export interface AppealTerm {
  term: {
    id: string;
    label: string;
    factor: AppealFactor;
    facet: AppealFacet;
  };
  averageWeight: number;
}

interface AppealFactor {
  id: string;
  label: string;
}

interface AppealFacet {
  id: string;
  label: string;
}

export interface ForrigeBokPublication {
  id: string;
  isbn: string;
  title: string;
  subtitle: string;
  coverImage: string;
  datePublished: string;
  language: string;
  type: "AudioBook" | "Book" | "VideoGame" | "Movie";
}

interface ForrigeBokWorkResponse {
  works: ForrigeBokWork[];
  total: number;
}

interface ForrigeBokReadalikesResponse {
  readalikes: ForrigeBokWork[];
}

export interface VocabularyResponse {
  factors: VocabularyFactor[];
  facets: VocabularyFacet[];
  terms: VocabularyTerm[];
}

interface VocabularyFactor {
  name: string;
  id: string;
}

interface VocabularyFacet {
  name: string;
  id: string;
  factorId: string;
}

export interface VocabularyTerm {
  name: string;
  id: string;
  factorId: string;
  facetId: string;
  definition: string;
  synonyms: string[];
  scopeNote: string | null;
}

const isForrigeBokWork = (item: unknown): item is ForrigeBokWork =>
  typeof item === "object" && typeof item?.["id"] === "string";

const isVocabularyFactor = (item: unknown): item is VocabularyFactor =>
  typeof item === "object" && typeof item?.["id"] === "string" && typeof item?.["name"] === "string";

const isVocabularyFacet = (item: unknown): item is VocabularyFacet =>
  typeof item === "object" && typeof item?.["id"] === "string" && typeof item?.["name"] === "string";

const isVocabularyTerm = (item: unknown): item is VocabularyTerm =>
  typeof item === "object" && typeof item?.["id"] === "string" && typeof item?.["name"] === "string";

const isForrigeBokWorkResponse = (data: unknown): data is ForrigeBokWorkResponse =>
  typeof data === "object" &&
  typeof data?.["total"] === "number" &&
  Array.isArray(data?.["works"]) &&
  !!data?.["works"]?.every(isForrigeBokWork);

const isForrigeBokReadalikesResponse = (data: unknown): data is ForrigeBokReadalikesResponse =>
  typeof data === "object" && Array.isArray(data?.["readalikes"]) && !!data?.["readalikes"]?.every(isForrigeBokWork);

const isVocabularyResponse = (data: unknown): data is VocabularyResponse =>
  typeof data === "object" &&
  Array.isArray(data?.["factors"]) &&
  Array.isArray(data?.["facets"]) &&
  Array.isArray(data?.["terms"]) &&
  !!data?.["factors"]?.every(isVocabularyFactor) &&
  !!data?.["facets"]?.every(isVocabularyFacet) &&
  !!data?.["terms"]?.every(isVocabularyTerm);

interface QueryParams {
  query?: string;
  isbn?: string;
  workId?: string;
  limit?: string;
  language: ForrigebokLanguage;
}

const works = async <ForrigeBokWork>(
  params: QueryParams,
  urlString: string
): Promise<{ result?: ForrigeBokWork[]; error?: string }> => {
  const searchParams = new URLSearchParams({ ...params });
  const url = `${urlString}${searchParams.toString()}`;

  const response = await fetch(url, {
    headers: {
      "Client-Identifier": "Libry Content",
    },
  });
  const body = await response.text();

  if (!response.ok) {
    try {
      const data = JSON.parse(body);
      return { error: data.error };
    } catch {
      return { error: body };
    }
  }

  const data = JSON.parse(body);

  let result;

  if (isForrigeBokWorkResponse(data)) {
    result = data.works.map((work) => {
      return work;
    });
    return { result };
  }

  if (isForrigeBokReadalikesResponse(data)) {
    result = data.readalikes.map((work) => {
      return work;
    });
    return { result };
  }

  return { error: `Unexpected return from forrigebok: "${JSON.stringify(data)}"` };
};

const vocabulary = async <VocabularyResponse>(url): Promise<{ result?: VocabularyResponse; error?: string }> => {
  const response = await fetch(url, {
    headers: {
      "Client-Identifier": "Libry Content",
    },
  });

  const body = await response.text();

  if (!response.ok) {
    try {
      const data = JSON.parse(body);
      return { error: data.error };
    } catch {
      return { error: body };
    }
  }

  const data = JSON.parse(body);

  if (!isVocabularyResponse(data)) {
    return { error: `Unexpected return from forrigebok: "${JSON.stringify(data)}"` };
  }

  const result = data as unknown as VocabularyResponse;

  return { result };
};

export const findForrigeBokWorkByIsbn = async (isbn: string, language?: string) => {
  const url = "https://forrigebok.no/api/v2023-01-12/works?";
  return works<ForrigeBokWork>({ isbn, language: getForrigebokLanguage(language) }, url);
};

export const findForrigeBokWorkByWorkId = async (workId: string, language?: string) => {
  const url = "https://forrigebok.no/api/v2023-01-12/works?";
  return works<ForrigeBokWork>({ workId, language: getForrigebokLanguage(language) }, url);
};

export const findForrigeBokReadalikesByIsbn = async (isbn: string, limit = "6", language?: string) => {
  const url = "https://forrigebok.no/api/v2023-01-12/readalikes?";
  return works<ForrigeBokWork>({ isbn, limit, language: getForrigebokLanguage(language) }, url);
};

export const findForrigeBokReadalikesByWorkId = async (workId: string, limit = "2", language?: string) => {
  const url = "https://forrigebok.no/api/v2023-01-12/readalikes?";
  return works<ForrigeBokWork>({ workId, limit, language: getForrigebokLanguage(language) }, url);
};

/** Dette er et stort kall med mye data, trenger du det egentlig? */
export const getForrigeBokVocabulary = async (language?: string) => {
  const url = `https://forrigebok.no/api/v2023-01-12/vocabulary?language=${getForrigebokLanguage(language)}`;
  return vocabulary<VocabularyResponse>(url);
};

const forrigebokLanguages = ["nb", "nn"] as const;
type ForrigebokLanguage = (typeof forrigebokLanguages)[number];
const isForrigebokLanguage = (language?: string): language is ForrigebokLanguage =>
  forrigebokLanguages.includes(language as ForrigebokLanguage) ? true : false;
const getForrigebokLanguage = (prefferedLanguage?: string): ForrigebokLanguage =>
  isForrigebokLanguage(prefferedLanguage) ? prefferedLanguage : "nb";
