import { DocumentType } from "@biblioteksentralen/cordata";
import { LocalizedField, concatenateLocalizedStrings } from "@libry-content/localization";

const getDefiniteNoun = (documentType: DocumentType | undefined) => {
  switch (documentType?.format.toLowerCase()) {
    case "film":
      return { nb: "filmen", nn: "filmen" };
    case "dataspill":
      return { nb: "spillet", nn: "spelet" };
    default:
      return { nb: "boka", nn: "boka" };
  }
};

const getDeterminative = (documentType: DocumentType | undefined) => {
  switch (documentType?.format.toLowerCase()) {
    case "film":
      return { nb: "denne", nn: "denne" };
    case "dataspill":
      return { nb: "dette", nn: "dette" };
    default:
      return { nb: "denne", nn: "denne" };
  }
};

export const getDocumentTypeDefiniteNoun = (
  documentType: DocumentType | undefined,
  { withDeterminative } = { withDeterminative: false }
): LocalizedField<string> =>
  withDeterminative
    ? concatenateLocalizedStrings(undefined, getDeterminative(documentType), getDefiniteNoun(documentType))
    : getDefiniteNoun(documentType);

export const getDocumentTypeIndefiniteNoun = (documentType: DocumentType | undefined): LocalizedField<string> => {
  switch (documentType?.format.toLowerCase()) {
    case "film":
      return { nb: "film", nn: "film" };
    case "dataspill":
      return { nb: "spill", nn: "spel" };
    default:
      return { nb: "bok", nn: "bok" };
  }
};

export const getDocumentTypeLabel = ({ medium, format }: DocumentType): string => {
  if (medium && ["Film", "Musikk", "Dataspill"].includes(format)) return medium;
  return format;
};
