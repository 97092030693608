import { Manifestation, Work } from "@biblioteksentralen/cordata";
import { filterManifestationsOnCatalogueId } from "./manifestations";
import { movieWorkTypeUri } from "../searchApi";

const isNotEbook = ({ documentType }: Pick<Manifestation, "documentType">) =>
  documentType?.format?.toLowerCase().replace("-", "") !== "ebook";

/**
 * Filter out:
 * - Manifestations and expressions that are not in the library's collection
 * - Ebook-manifestations and expressions (temporary solution; can lead to empty works in the frontend)
 */
export const filterWorkData =
  <WorkType extends Partial<Work>>(libraryCatalogueId: string | null) =>
  (work: WorkType): WorkType => {
    if (!libraryCatalogueId) return work;

    const expressions = work.expressions
      ?.map(({ manifestations = [], aggregateManifestations = [], ...expression }) => ({
        ...expression,
        manifestations: filterManifestationsOnCatalogueId(manifestations, libraryCatalogueId).filter(isNotEbook),
        aggregateManifestations: filterManifestationsOnCatalogueId(aggregateManifestations, libraryCatalogueId).filter(
          isNotEbook
        ),
      }))
      .filter(
        ({ manifestations = [], aggregateManifestations = [] }) =>
          manifestations.length > 0 || aggregateManifestations.length > 0
      );

    return { ...work, expressions };
  };

export const isMovie = (work: Pick<Work, "workTypes">) => work.workTypes.every(({ uri }) => uri === movieWorkTypeUri);
